import {useEffect, useRef, useState} from "react";
import * as d3 from "d3";
import {Legend} from "../../../../shared/utils/Legend";
import {getPlot4Data} from "../../../../slices/allServiceSlice";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../../shared/config/ConfigContext";

const Plot4 = ({width, height, projectId, pipelineId}) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    const svgRef = useRef();
    const correctionScoreRef = useRef();
    const [timeData, setTimeData] = useState([]);
    const [timepointsList, setTimepointsList] = useState([]);

    const processData = (input) => {
        let timeData = [];

        // Process each row of data
        input.forEach(row => {
            timeData.push({
                row: row.row,
                col: row.col,
                val: parseFloat(row.val)
            });
        });

        return timeData;
    }

    useEffect(() => {
        dispatch(getPlot4Data({apiBaseUrl, projectId, pipelineId}))
    }, [dispatch, apiBaseUrl])
    useEffect(() => {
        setTimeData(allService.plots.plot4.content)
    }, [allService.plots.plot4.content])
    useEffect(() => {
        setTimepointsList([
            ...new Set(
                d3.map(timeData, function (d) {
                    return d.row;
                })
            )
        ]);
    }, [timeData]);

    useEffect(() => {
        const margin = { top: 10, right: 50, bottom: 50, left: 5 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        const svg = d3
            .select(svgRef.current)
            .attr('width', width)
            .attr('height', height);

        svg.selectAll('*').remove();

        const g = svg
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const x = d3.scaleBand().range([0, innerWidth]).domain(timepointsList).padding(0.05);
        const y = d3.scaleBand().range([innerHeight, 0]).domain(timepointsList).padding(0.05);

        const myColor = d3.scaleSequential().interpolator(d3.interpolateViridis).domain([-1, 1]);

        const tooltip = d3
            .select('body')
            .append('div')
            .style('opacity', 0)
            .attr('class', 'tooltip')
            .style('background-color', 'white')
            .style('color', 'black')
            .style('border', 'solid')
            .style('border-width', '2px')
            .style('border-radius', '5px')
            .style('padding', '5px')
            .style('position', 'absolute');

        const mouseover = function () {
            tooltip.style('opacity', 1);
            d3.select(this).style('stroke', 'black').style('opacity', 1);
        };

        const mousemove = function (event, d) {
            tooltip
                .html(
                    `Row: ${d.row}<br>Col: ${d.col}<br>Correlation: ${Math.round(d.val * 100000) / 100000}`
                )
                .style('top', event.clientY - 10 + 'px')
                .style('left', event.clientX + 10 + 'px');
        };

        const mouseleave = function () {
            tooltip.style('opacity', 0);
            d3.select(this).style('stroke', 'none').style('opacity', 0.8);
        };

        g.selectAll('rect')
            .data(timeData, (d) => d.row + ':' + d.col)
            .enter()
            .append('rect')
            .attr('x', (d) => x(d.row))
            .attr('y', (d) => y(d.col))
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('width', x.bandwidth())
            .attr('height', y.bandwidth())
            .style('fill', (d) => myColor(d.val))
            .style('stroke-width', 4)
            .style('stroke', 'none')
            .style('opacity', 0.8)
            .on('mouseover', mouseover)
            .on('mousemove', mousemove)
            .on('mouseleave', mouseleave)
            .append('title')
            .text(
                (d) =>
                    'Row: ' +
                    d.col +
                    '\n' +
                    'Col: ' +
                    d.row +
                    '\n\n' +
                    'Correlation: ' +
                    Math.round(d.val * 100000) / 100000
            );

        g.append('g')
            .attr('transform', `translate(0, ${innerHeight})`)
            .call(d3.axisBottom(x))
            .selectAll('text')
            .attr('transform', 'rotate(-45)')
            .style('text-anchor', 'end');

        g.append('g')
            .attr('transform', `translate(${innerWidth}, 0)`)
            .call(d3.axisRight(y));

        return () => {
            tooltip.remove();
        };
    }, [timepointsList, timeData, width, height]);

    useEffect(() => {
        if (correctionScoreRef.current) {
            const legendNode = Legend(d3.scaleSequential([-1, 1], d3.interpolateViridis), {
                title: "Correlation Score"
            })
            correctionScoreRef.current.appendChild(legendNode);
        }
        return () => {
            if (correctionScoreRef.current && correctionScoreRef.current.lastChild) {
                correctionScoreRef.current.removeChild(correctionScoreRef.current.lastChild);
            }
        };
    }, [timepointsList, timeData, width, height]);
    return (
        <div>
            <div ref={correctionScoreRef} className={"pl-4 py-4"} />
            <svg ref={svgRef} width={width} height={height} />
        </div>
    );
}

export default Plot4;